<template>
  <div class="actions">
    <router-link
      :to="{name: 'pack-abonnment-detail', params: {uid: object.uid}}"
      class="btn btn-secondary btn-sm"
    >
      <i class="icofont icofont-eye" />
    </router-link>
    <a
      class="btn btn-third btn-sm"
      @click.prevent="deleting"
      v-if="is_super_admin"
    ><i class="icofont icofont-close" /></a>
  </div>
</template>
  
  <script>
  import {mapGetters} from 'vuex'
import { DELETE_PACK_ABONNEMENT } from '../../../graphql/pack';
  export default {
      props : {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
          deleting(){
            try{
                this.$apollo.mutate({
                  mutation: DELETE_PACK_ABONNEMENT,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (cache, { data: { deletePackAbonnement } }) => {
                      if(deletePackAbonnement) console.log(`Abonnement  ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting analyse ${this.object.uid}`)
                  }
                })
            }catch(error) {
                console.log(error)
            }
          }
      },
      computed: {
          ...mapGetters({
              is_super_admin: 'auth/is_super_admin',
          }),
      }
  
  }
  </script>
  
  <style>
  
  </style>